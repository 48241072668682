<template>
  <div id="opening">
    <div class="openbox">
      <div class="playbar"></div>
    </div>
    <div class="openword">Kizuna Trajectory</div>
    <div class="openword opensub"><fa :icon="{ prefix: 'fab', iconName: 'youtube' }" />A.I.Channel 5th Anniversary</div>
  </div>

  <div id="modalOverlay" v-on:click="closeModal">
    <div id="modalContent" v-on:click.stop="stopCloseModal">
      <p style="text-align: center;"><span style="font-family: Spartan; font-size: 150%;">Kizuna Trajectory 2021</span></p>
      <p>キズナアイちゃん、YouTube活動5周年おめでとう。</p>
      <p>つながってくれてありがとう。</p>
      <p>これまでの動画のサムネイルから色をピックアップして、5年間の軌跡を振り返りました。<br><span style="font-size: 80%;">※ダークモードを設定している場合は一旦解除していただくと、より可愛く見えます。</span></p>

      <p>画面下のボタンから、お好みの色の動画を観ることができます。</p>
      <p>今日の気分の色で選んでみてくださいね。</p>

      <p>アイちゃんがこれからも楽しく活動していけるよう、ずっと応援しています。</p>

      <p style="text-align: right; font-family: Spartan;">2021.12.01<br />うるっち <a href="https://twitter.com/UltraGreenCar" target="_blank"><span style="color: rgba(29, 161, 242, 1);"><fa :icon="{ prefix: 'fab', iconName: 'twitter' }" /></span></a></p>
      
      <p style="text-align: center;">
        <a href="http://twitter.com/share?url=https://kizunatrajectory2021.pages.dev&text=Kizuna Trajectory 2021&hashtags=KizunaTrajectory2021" target="_blank">
        <button class="shareButton">
          <fa :icon="{ prefix: 'fab', iconName: 'twitter' }" /> Share
        </button>
        </a>
      </p>      
    </div>
  </div>

  <div id="settingOverlay" v-on:click="closeSetting(); randomSelect(resultA.slice(), 5)">
    <div id="settingContent" v-on:click.stop="stopCloseSetting">
      <div id="labelParent">
        <input id="radioAnd" type="radio" v-model="casts[0]" v-bind:value="true" checked>
        <label for="radioAnd">ONLY</label>
        <input id="radioOr" type="radio" v-model="casts[0]" v-bind:value="false">
        <label for="radioOr">OR</label>
      </div>
      <div id="checkboxParent">
        <label><input type="checkbox" v-model="casts[1]"><span id="ai">🎀</span></label>
        <label><input type="checkbox" v-model="casts[2]"><span id="love"><fa :icon="{ prefix: 'fas', iconName: 'hashtag' }" /></span></label>
        <label><input type="checkbox" v-model="casts[3]"><span id="pii"><fa :icon="{ prefix: 'fas', iconName: 'asterisk' }" /></span></label>
      </div>
    </div>
  </div>

  <div class="artbase">
    <div v-for="(value, key) in 1" :key="key" class="videoboxa" id="videoa">
      <div v-for="(value, key) in resultA" :key="key" class="videobox" v-bind:id="'keyId' + value.keyId" v-bind:style="{background: 'rgba(' + (value.red * 1.1) + ', ' + (value.green * 1.1) + ', ' + (value.blue * 1.1) + ', 1)'}"></div>
    </div>
  </div>

  <div class="subbox"></div>
  <div id="modalOpenButton" v-on:click="openModal"><fa :icon="{ prefix: 'fas', iconName: 'info' }" /></div>
  <div id="dayWrapper">
    <div class="capbase"></div>
    <div class="agobase"></div>
  </div>
  <div id="backButton" v-on:click="changeOpen(false)"><fa :icon="{ prefix: 'fas', iconName: 'undo' }" /></div>

  <footer class="footer">
    <div class="footerpom">
      <div class="selChange" id="setcog" v-on:click="openSetting"><fa :icon="{ prefix: 'fas', iconName: 'cog' }" /></div>
      <div v-for="(value, key) in random5" :key="key" class="sel" v-bind:style="{background: 'rgba(' + (value.red * 1.1) + ', ' + (value.green * 1.1) + ', ' + (value.blue * 1.1) + ', 1)'}" v-on:click="setKeyId(value.keyId); changeOpen(true)"></div>
      <div class="selChange" id="refresh" v-on:click="randomSelect(resultA.slice(), 5)"><fa :icon="{ prefix: 'fas', iconName: 'sync' }" /></div>
    </div>
  </footer>
</template>

<script>
import anime from 'animejs'
import resultData from './assets/resultData.json'
import $ from 'jquery'

export default {
  components: {
  },
  data() {
    let castCodes = [];
    castCodes.push(0);
    let castSel = [true, true, false, false];

    let rana = resultData.filter(d => castCodes.includes(d.castCode));
    const random = randomSelect(rana.slice(), 5);
    function randomSelect(array, num) {
        let newArray = [];
        
        while(newArray.length < num && 0 < array.length) {
          const rand = Math.floor(Math.random() * array.length);
          if(!newArray.some(d => d.keyId == array[rand].keyId)) {
            newArray.push(array[rand]);
            array.slice(rand, 1);
          }
        }

        return newArray;
    }

    return {
      // 展開中?
      isOpen: false,
      // 動画KeyID
      keyId: -1,
      // 動画データ
      resultA: resultData,
      // ランダムに選択した5つのデータ
      random5: random,
      // モーダル
      showModal: false,
      // 設定画面
      showSetting: false,
      // castCode配列
      castCodeArray: castCodes,
      // 選択状態
      casts: castSel,
      // カーテンアニメ
      curtainAnime: null
    }
  },
  methods: {
    createCurtainAnime: function() {
        let art = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19];
        this.curtainAnime = anime.timeline({loop: true, autoplay: false})
              .add({
                targets: '.videobox',
                opacity: function(el, i) {
                  if(art.includes(resultData[i].colorPatternCode)) {
                    return [1, 1];
                  } else {
                    return [1, 0.1];
                  } 
                },
                delay: (el, i) => 2 * i,
                easing: 'easeInOutSine',
                duration: 500
              }, 5000)
              .add({
                targets: '.videobox',
                opacity: function(el, i) {
                  if(art.includes(resultData[i].colorPatternCode)) {
                    return [1, 1];
                  } else {
                    return [0.1, 1];
                  } 
                },
                delay: (el, i) => 2 * i,
                easing: 'easeInOutSine',
                duration: 500
              }, 18000);
    },

    openSetting: function() {
      this.showSetting = true;
      anime({
        targets: '#setcog',
        rotate: [0, 60],
        easing: 'easeOutBack',
        duration: 300,
      });
    },

    closeSetting: function() {
      this.showSetting = false;
      anime({
        targets: '#setcog',
        rotate: [60, 0],
        easing: 'easeOutBack',
        duration: 300,
      });
    },

    stopCloseSetting: function() {
      // DO NOTHING
    },

    openModal: function() {
      this.showModal = true;
    },

    closeModal: function() {
      this.showModal = false;
    },

    stopCloseModal: function() {
      // DO NOTHING
    },

    setKeyId: function(keyId) {
      this.keyId = keyId;
    },

    changeOpen: function(open) {
      this.isOpen = open;
    },

    randomSelect: function(array, num) {
      let newArray = [];

      let filteredArray = array.filter(d => this.castCodeArray.includes(d.castCode));

        while(newArray.length < num && 0 < filteredArray.length) {
          const rand = Math.floor(Math.random() * filteredArray.length);
          if(!newArray.some(d => d.keyId == filteredArray[rand].keyId)) {
            newArray.push(filteredArray[rand]);
            filteredArray.slice(rand, 1);
          }
        }

        const changeColor = () => {
          this.random5 = newArray;
        };

        anime.timeline({loop: false})
          .add({
            targets: '.sel',
            scale: 0,
            easing: 'easeOutSine',
            duration: 300,
          }, 0)
          .add({
            targets: '#refresh',
            rotate: [0, 360],
            easing: 'easeOutSine',
            duration: 300,
          }, 0)
          .add({
            begin: changeColor,
            targets: '.sel',
            scale: 1,
            easing: 'easeOutSine',
            duration: 300,
          }, 300);    
    }
  },
  watch: {

    casts: {
      handler: function() {
        let resArray = [];

        switch(this.casts[0]) {
          // ONLY
          case true:
            if((this.casts[1] && !this.casts[2] && !this.casts[3]) || (!this.casts[1] && !this.casts[2] && !this.casts[3])) {
              resArray.push(0);
            } else if(!this.casts[1] && this.casts[2] && !this.casts[3]) {
              resArray.push(2);
            } else if(!this.casts[1] && !this.casts[2] && this.casts[3]) {
              resArray.push(3);
            } else if(this.casts[1] && this.casts[2] && !this.casts[3]) {
              resArray.push(4);
            } else if(this.casts[1] && !this.casts[2] && this.casts[3]) {
              resArray.push(5);
            } else if(!this.casts[1] && this.casts[2] && this.casts[3]) {
              resArray.push(6);
            } else {
              resArray.push(7);
            }
            break;
          // OR
          default:
            if(this.casts[1]) {
              resArray.push(0, 4, 5, 7);
            }
            if(this.casts[2]) {
              resArray.push(2, 4, 6, 7);
            }
            if(this.casts[3]) {
              resArray.push(3, 5, 6, 7);
            }
            if(!this.casts[1] && !this.casts[2] && !this.casts[3]) {
              resArray.push(0, 4, 5, 7);
            }
            break;
        }

        this.castCodeArray = [];
        this.castCodeArray = Array.from(new Set(resArray));
      },
      deep: true
    },

    showModal: function() {
      if(this.showModal) {
        anime.timeline({loop: false})
        .add({
          begin: function() {
            $('#modalOverlay').css({'display': 'flex'});
          },
          targets: '#modalOverlay',
          opacity: [0, 1],
          duration: 500,
          easing: 'linear'
        }, 0)
        .add({
          targets: '#modalOverlay p',
          opacity: [0, 1],
          translateY: [50, 0],
          duration: 800,
          easing: 'easeInOutBack'
        }, 500);
      } else {
        anime.timeline({loop: false})
        .add({
          targets: '#modalOverlay',
          opacity: [1, 0],
          duration: 500,
          easing: 'linear',
          complete: function() {
            $('#modalOverlay').css({'display': ''});
          }
        }, 0)
        .add({
          targets: '#modalOverlay p',
          opacity: [1, 0],
          duration: 500,
          easing: 'linear'
        }, 0);
      }
    },

    showSetting: function() {
      if(this.showSetting) {
        anime.timeline({loop: false})
        .add({
          begin: function() {
            $('#settingOverlay').css({'display': 'flex'});
          },
          targets: '#settingOverlay',
          opacity: [0, 1],
          duration: 500,
          easing: 'linear'
        }, 0);
      } else {
        anime.timeline({loop: false})
        .add({
          targets: '#settingOverlay',
          opacity: [1, 0],
          duration: 500,
          easing: 'linear',
          complete: function() {
            $('#settingOverlay').css({'display': ''});
          }
        }, 0);
      }
    },

    isOpen: function() {

      if(this.isOpen) {

      this.curtainAnime.restart();
      this.curtainAnime.seek(5000);
      this.curtainAnime.pause();

      let keyId = this.keyId;

      let info = resultData.find(d => d.keyId == keyId);
      let subcolor;
      if(info.channelCode === 0) {
        subcolor = 'rgba(238, 79, 135, 0.8)';
      } else {
        subcolor = 'rgba(26, 204, 206, 0.8)';
      }

      let pub = info.publishedDateTime;
      let replaced = pub.replace(/-/g, '/').replace('T', ' ');
      let pubdate = new Date(new Date(replaced).toDateString());
    
      $('.capbase').append(pubdate.getFullYear() + '.' + ('00' + (pubdate.getMonth() + 1)).slice(-2) + '.' + ('00' + pubdate.getDate()).slice(-2));

      let today = new Date(new Date().toDateString());
      let diff = today.getTime() - pubdate.getTime();
      let days = Math.floor(diff / (1000 * 60 * 60 * 24));
      let multi = days == 1 ? 'day' : 'days';
      $('.agobase').append(days + ' ' + multi +' ago ');

      let textWrapper = document.querySelector('.capbase');
      textWrapper.innerHTML = textWrapper.textContent.replace(/\S/g, "<span class='letter'>$&</span>");

      $('.subbox').get(0).style.setProperty('--subboxColor', subcolor);

      let hako = $('#keyId' + keyId);
      
      let hako2 = $(hako).clone(false).attr('id', 'testes');

      hako2.removeClass('videobox');

      $(hako).css({
        'opacity': 0
      });

      $(hako2).css({
        'opacity': 1,
        'z-index': 20,
        'position': 'absolute',
        'top': hako.offset().top,
        'left': hako.offset().left,
        'width': hako.width(),
        'height': hako.height()
      });
      $('#app').append($(hako2));

      $('#testes').css({'marginTop': 0 + 'px'});

      let me = this;

      anime({
          targets: '.sel, .selChange',
          translateY: 'calc(20vh + 100px)',
          opacity: 0,
          duration: 300,
          easing: 'easeInBack',
        });

      anime({
        targets: '#modalOpenButton',
        opacity: 0,
        duration: 300,
        easing: 'easeInOutSine'
      });

      anime({
        begin: function() {
          $('#backButton').css({'display': 'flex', 'cursor': 'pointer'});
        },
        targets: '#backButton',
        opacity: 1,
        duration: 300,
        easing: 'easeInOutSine',
      });

     anime.timeline({loop: false})
        .add({
          targets: '#testes',
          top: '50vh',
          left: 10,
          rotate: '5turn',
          easing: 'easeOutSine',
          scale: 5,
          duration: 500,
          complete: function() {
            $('#testes').css({'top': '', 'left': ''});
            $('#testes').addClass('tesC');
            $('#testes').css({'marginTop' : ''});
          }
        }, 0)
        .add({
          targets: '#testes',
          width: function() {
            if(window.matchMedia('(max-width: 959px)').matches) {
              return '96vmin'
            } else {
              return '70vw'
            }            
          },
          height: function() {
            if(window.matchMedia('(max-width: 959px)').matches) {
              return '54vmin'
            } else {
              return '39vw'
            } 
          },
          easing: 'easeOutQuint',
          scale: 1,
          duration: 400,
          complete: function() {
            $('#testes').css({'width': '', 'height': ''});
          }
        }, 550)
        .add({
          targets: '.subbox',
          translateX: -80 + 'vw',
          easing: 'easeOutQuint',
          duration: 450,
          complete: function() {
             let chome = resultData.find(v => v.keyId == keyId);
              $('#testes').append("<div class='youtube'><iframe width='560' height='315' src='https://www.youtube.com/embed/" + chome.url + "?rel=0' title='YouTube video player' frameborder='0' allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture' allowfullscreen></iframe></div>"); 
          }
        }, 550)
        .add({
          targets: '.capbase .letter',
          opacity: [0, 1],
          duration: 100,
          delay: (el, i) => 10 * i
        }, 1000)
        .add({
          targets: '.agobase',
          opacity: [0, 1],
          translateY: [20, 0],
          duration: 800,
          complete: function() {
            me.curtainAnime.play();
          }
        });

      } else {

        if($('#testes')[0]) {
          $('#testes').removeClass('videobox');

          let keyId = this.keyId;
          let hako = $('#keyId' + keyId);

          let offTop = $('#testes').offset().top;

          $('#testes').css({'marginTop': 0 + 'px'});

          if(window.matchMedia('(max-width: 959px)').matches) {
            $('#testes').css({'marginLeft': 'calc((100vw - 96vmin) / 4)'});
          }
          
          let offLeft = $('#testes').offset().left;
          let offWidth = $('#testes').width();
          let offHeight = $('#testes').height();

          anime({
            targets: '#testes',
            top: offTop + 'px',
            left: offLeft + 'px',
            duration: 10
          });

          anime({
            targets: '#modalOpenButton',
            opacity: 1,
            duration: 300,
            easing: 'easeInOutSine'
          });

          anime({
            targets: '#backButton',
            opacity: 0,
            duration: 300,
            easing: 'easeInOutSine',
            complete: function() {
              $('#backButton').css({'display': 'none', 'cursor': ''});
            },
          });

          let me = this;

          anime.timeline({loop: false})
              .add({
                begin: function() {
                  me.curtainAnime.restart();
                  me.curtainAnime.pause();
                  $('#keyId' + keyId).css({'opacity': '0'});
                },
                targets: '#testes',
                top: offTop + 'px',
                left: offLeft + 'px',
                duration: 10
              }, 0)
              .add({
                targets: '#testes .youtube',
                opacity: 0,
                duration: 300,
                easing: 'linear',
                complete: function() {
                  $('#testes').empty();
                }
              }, 0)
              .add({
                targets: '.agobase',
                opacity: [1, 0],
                translateY: [0, 20],
                duration: 600,
                complete: function() {
                  $('.agobase').empty();
                }
              }, 0)
              .add({
                targets: '.capbase .letter',
                opacity: [1, 0],
                duration: 100,
                delay: (el, i) => 10 * i,
                complete: function() {
                  $('.capbase').empty();
                }
              }, 0)
              .add({
                targets: '.subbox',
                translateX: 80 + 'vw',
                easing: 'easeOutQuint',
                duration: 800
              }, 0)        
              .add({
                begin: function() {
                  $('#testes').removeClass('tesC');
                },
                targets: '#testes',
                top: [offTop, hako.offset().top + 'px'],
                left: function () {
                  if(window.matchMedia('(max-width: 959px)').matches) {
                    return [offLeft, 'calc(' + hako.offset().left + 'px - ((100vw - 96vmin) / 4))'];
                  } else {
                    return [offLeft, (hako.offset().left + 'px')];
                  }
                },
                width: [offWidth, hako.width() + 'px'],
                height: [offHeight, hako.height() + 'px'],
                easing: 'easeInOutBack',
                duration: 600,
                complete: function() {
                  $('#testes').remove();
                  $(hako).css({
                    'opacity': 1
                  });
                  me.curtainAnime.play();
                }
              }, 600);           
        }

      anime({
        targets: '.sel, .selChange',
        translateY: 0,
        opacity: 1,
        duration: 1000
      });
      }

    }

  },
  
  created() {
    // DO NOTHING
  },
  mounted() {

    const setHeight = () => {
      const vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    }

    window.addEventListener('resize', setHeight, false);
    setHeight();

    let openingWrapper = document.querySelector('.openword');
    openingWrapper.innerHTML = openingWrapper.textContent.replace(/\S/g, "<span class='letter'>$&</span>");

    for(let i = 0; i < 100; i++) {
      let r = Math.floor(Math.random() * 255);
      let g = Math.floor(Math.random() * 255);
      let b = Math.floor(Math.random() * 255);
      let rgba = "rgba(" + r + ", " + g + ", " + b + ", 1)";

      $('#opening').append('<div class="minibox" style="background-color: ' + rgba + ';"></div>');
    }

    for(let i = 0; i < 100; i++) {
      let r = Math.floor(Math.random() * 255);
      let g = Math.floor(Math.random() * 255);
      let b = Math.floor(Math.random() * 255);
      let rgba = "rgba(" + r + ", " + g + ", " + b + ", 1)";

      $('#opening').append('<div class="minibox2" style="background-color: ' + rgba + ';"></div>');
    }

    let me = this;

    anime.timeline({loop: false})
      .add({
        targets: '.openword .letter',
        opacity: [0, 1],
        scale: [0.2, 1],
        translateY: ['5vh', 0],
        easing: 'easeInOutSine',
        delay: (el, i) => 50 * (i + 1),
        duration: 500
      }, 1000)
      .add({
        targets: '.openbox',
        opacity: [0, 1],
        width: [0, '50vw'],
        easing: 'easeInOutQuart',
        duration: 1000
      }, 2000)
      .add({
        targets: '.opensub',
        opacity: [0, 1],
        translateY: [20, 0],
        easing: 'easeOutSine',
        duration: 500
      }, 2100)
      .add({
        targets: '.minibox',
        opacity: [1, 0],
        scale: function() {
          return [anime.random(1, 5), 1];
        },
        top: function() {
          let pom = anime.random(0, 1);

          let result = 0;
          if(pom == 0) {
            result = -50;
          } else {
            result = 150;
          }
          return [result + 'vh', '50%'];
        },
        left: function() {
          return [anime.random(-50, 150) + 'vw', '50%'];
        },
        easing: 'easeOutQuart',
        duration: 2000,
        delay: (el, i) => i * 30
      }, 3000)
      .add({
        targets: '.minibox2',
        opacity: [1, 0],
        scale: function() {
          return [anime.random(1, 5), 1];
        },
        top: function() {
          return [anime.random(-10, 110) + 'vh', '50%'];
        },
        left: function() {
          let pom = anime.random(0, 1);

          let result = 0;
          if(pom == 0) {
            result = -50;
          } else {
            result = 150;
          }
          return [result + 'vw', '50%'];
        },
        easing: 'easeOutQuart',
        duration: 2000,
        delay: (el, i) => i * 30
      }, 3000)
      .add({
        targets: '.playbar',
        width: ['0%', '96%'],
        easing: 'easeInOutSine',
        duration: 3000
      }, 3000)
      .add({
        targets: '#opening',
        backgroundColor: 'rgba(0, 0, 0, 0)',
        easing: 'easeInOutSine',
        duration: 3000
      }, 4000)
      .add({
        targets: '.openbox',
        scale: [1, 0],
        opacity: [1, 0],
        duration: 2000,
        complete: function() {
          $('#opening').remove();
        }
      }, 6500)
      .add({
        targets: '.openword',
        opacity: [1, 0],
        duration: 500
      }, 6500)
      .add({
        targets: '.videobox',
        opacity: [0, 1],
        scale: [0, 1],
        rotate: '1turn',
        delay: (el, i) => 1 * i,
        translateX: 0 + 'px',
        direction: 'alternate',
        easing: 'easeInOutSine',
        complete: function() {
          me.createCurtainAnime();
          me.curtainAnime.seek(3000);
          me.curtainAnime.play();
        }
      }, 7000)
      .add({
        targets: '.sel, .selChange',
        opacity: [0, 1],
        translateY: [100, 0],
        easing: 'easeOutBack',
        duration: 800
      }, 9500)
      .add({
        targets: '#modalOpenButton',
        opacity: [0, 1],
        translateX: [100, 0],
        easing: 'easeOutBack',
        duration: 800
      }, 9500);
  }
}
</script>

<style>
@import url('https://fonts.googleapis.com/css?family=Spartan:300&display=swap');

body {
  min-height: 100vh;
  min-height: calc(var(--vh, 1vh) * 100);
  overflow: hidden;
  font-family: sans-serif;
}

:root {
  --subboxColor: rgba(238, 79, 135, 0.8);
}

::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

::-webkit-scrollbar-track {
  border-radius: 5px;
}

::-webkit-scrollbar-thumb {
  background: rgba(255, 255, 255, 1);
  border-radius: 5px;
}

#opening {
  z-index: 100;
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-color: rgba(255, 255, 255, 1);
  top: 0;
  left: 0;
}

.openbox {
  z-index: 102;
  position: fixed;
  inset: 0;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  width: 50vw;
  max-width: 1536px;
  max-height: 864px;
  height: calc(50vw * 9 / 16);
  background-color: rgba(255, 137, 188, 1);
}

.playbar {
  width: 0;
  height: 5px;
  background-color: red;
  margin-left: 2%;
  margin-top: 50%;
}

.openword {
  z-index: 103;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  inset: 0;
  margin: auto;
  width: 100vw;
  height: 1em;
  text-align: center;
  font-family: Spartan;
  font-size: calc(100vmin / 20);
}

.openword .letter {
  opacity: 0;
}

.opensub {
   padding-top: 5em;
   color: rgba(90, 90, 90, 1);
   font-size: calc(100vmin / 45);
}

.minibox, .minibox2 {
  z-index: 100;
  opacity: 0;
  width: 20px;
  height: 20px;
  position: fixed;
}

#modalOverlay, #settingOverlay{
  z-index:700;
  position:fixed;
  top:0;
  left:0;
  width:100vw;
  height:100vh;
  background-color:rgba(0,0,0,0.5);

  display: none;
  align-items: center;
  justify-content: center;
}

#modalContent, #settingContent {
  z-index:701;
  width:80vw;
  max-width: 900px;
  height: 35vh;
  max-height: 600px;
  overflow-y: scroll;
  padding: 1em;
  background:rgb(255, 180, 212);
  border-radius: 10px;
  font-size: min(16px, calc(9px + 0.5vw));
}

#settingContent {
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
}

#labelParent {
  width: 80%;
  max-width: 600px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

#labelParent input {
  display: none;
}

#labelParent label {
  cursor: pointer;
  flex-grow: 1;
  font-family: Spartan;
  text-align: center;
  padding: 10px;
  color: rgba(0, 0, 0, 1);
  text-shadow: 0 0 0 rgba(0, 0, 0, 0);
  background-color: rgba(240, 240, 240, 0.5);
  transition-property: color, text-shadow, box-shadow, background-color, transform;
  transition-duration: 0.3s;
  border: 1px solid rgba(255, 108, 161, 1);
  box-shadow: 0 3px 1px rgba(255, 108, 161, 0.8);
  border-radius: 5px;
  width: 100%;
}

#labelParent input:checked + label {
  color: white;
  text-shadow: 0px 1px 0px rgba(0, 0, 0, 0.8);
  box-shadow: 0 0 0 rgba(0, 0, 0, 0);
  transform: translate3d(0, 3px, 0);
  background-color: rgba(255, 108, 161, 1);
}

#checkboxParent {
  width: 80%;
  max-width: 600px;
  height: 100px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

#checkboxParent label input {
  display: none;
}

#checkboxParent label span {
  display: block;
  color: white;
  cursor: pointer;
  width: 8vw;
  max-width: 50px;
  height: 8vw;
  max-height: 50px;
  text-align: center;
  line-height: min(50px, 8vw);
  border-radius: 50%;
  background-color: rgba(240, 240, 240, 0.5);
  transition-property: color, text-shadow, background-color, transform;
  transition-duration: 0.3s;
  font-size: min(30px, 5vw);
}

#checkboxParent label span#ai {
  border: 1px solid rgba(238, 79, 135, 1);
  box-shadow: 0 3px 0 rgba(238, 79, 135, 1);
  color: transparent;
  text-shadow: 0 0 0 rgba(255, 108, 161, 1);
}

#checkboxParent label input:checked + span#ai {
  color: rgba(255, 255, 255, 1);
  text-shadow: 0 0 0 rgba(255, 255, 255, 0);
  background-color: rgba(255, 108, 161, 1);
  box-shadow: 0 0 0 rgba(255, 255, 255, 0);
  transform: translate3d(0, 3px, 0);
}

#checkboxParent label span#love {
  border: 1px solid rgba(0, 121, 198, 1);
  box-shadow: 0 3px 0 rgba(0, 121, 198, 1);
  color: rgba(66, 173, 255, 1);
}

#checkboxParent label input:checked + span#love {
  color: rgba(255, 255, 255, 1);
  background-color: rgba(66, 173, 255, 1);
  box-shadow: 0 0 0 rgba(255, 255, 255, 0);
  transform: translate3d(0, 3px, 0);
}

#checkboxParent label span#pii {
  border: 1px solid rgba(193, 151, 71, 1);
  box-shadow: 0 3px 0 rgba(193, 151, 71, 1);
  color: rgb(252, 177, 39);
}

#checkboxParent label input:checked + span#pii {
  color: rgba(255, 255, 255, 1);
  background-color: rgba(254, 205, 71, 1);
  box-shadow: 0 0 0 rgba(255, 255, 255, 0);
  transform: translate3d(0, 3px, 0);
}

.shareButton {
  background-color: rgba(29, 161, 242, 1);
  color: snow;
  border: none;
  cursor: pointer;
  outline: none;
  padding: 5px;
  border-radius: 5px;
  appearance: none;
  text-align: center;
  font-family: Spartan;
}

#modalOpenButton {
  position: fixed;
  top: 1vh;
  right: 1vw;
  z-index: 0;
  width: 10vw;
  max-width: 50px;
  height: 10vw;
  max-height: 50px;
  background-color: snow;
  color: gray;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: min(30px, 6vw);
}

.artbase {
  background: rgba(255, 255, 255, 0);
  box-shadow: 0px 0px 2vmin rgba(255, 255, 255, 1);
  position: absolute;
  inset: 0;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  width: 72vmin;
  height: 72vmin;
}

.videoboxa {
  display: flex;
  flex-wrap: wrap;
}

.videobox {
  width: calc((72vmin / 45) - 2px);
  height: calc((72vmin / 45) - 2px);
  margin: 1px;
}

#backButton {
  z-index: 250;
  opacity: 0;
  position: fixed;
  top: 1vh;
  left: 1vw;
  width: 10vw;
  max-width: 50px;
  height: 10vw;
  max-height: 50px;
  background-color: snow;
  color: gray;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: min(30px, 6vw);
}

footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  max-width: 1200px;
  height: min(11vw, 100px);
  left: 0;
  inset: 0;
  margin:  auto auto 5px;
}

.footerpom {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.sel {
  width: 10vw;
  max-width: 100px;
  height: 10vw;
  max-height: 100px;
  border-radius: 50%;
  cursor: pointer;
  box-shadow: 0px 0px 30px rgba(255, 255, 255, 0.6);
  text-align: center;
  font-size: min(3vw, 36px);
  color: rgba(238, 79, 135, 0.8);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.selChange {
  width: 7vw;
  max-width: 70px;
  height: 7vw;
  max-height: 70px;
  border-radius: 50%;
  cursor: pointer;
  box-shadow: 0px 0px 30px rgba(255, 255, 255, 0.6);
  text-align: center;
  font-size: min(4vw, 40px);
  color: rgba(238, 79, 135, 0.8);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: pink;
  transform: scale(0.7);
  opacity: 0.5;
}

#testes {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  will-change: scale, top, left, width, height;
}

.tesC {
  position: absolute;
  width: 70vw;
  height: 39vw;
  inset: 0;
  margin: auto 0 auto;
  left: 10px;
}

.youtube {
  width: 98%;
  aspect-ratio: 16 / 9;
}

.youtube iframe {
  display: block;
  z-index: 11;
  width: 100%;
  height: 100%;
  margin: auto;
}

.subbox {
  z-index: 1;
  position: fixed;
  right: -80vw;
  bottom: 0;
  width: 80vw;
  height: 100vh;
  background: var(--subboxColor);
  clip-path: polygon(30% 0, 100% 0, 100% 100%, 0 100%);
  box-shadow: -10px 1px 6px rgba(255, 0, 0, 1);
}

#dayWrapper {
  z-index: 10;
  position: fixed;
  top: 50vh;
  left: 71vw;
  width: 28vw;
  height: auto;
}

.capbase {
  font-family: Spartan;
  text-align: right;
  font-size: calc(28vw / 10);
}

.agobase {
  font-family: Spartan;
  text-align: right;
  padding-right: 0.2em;
}

.text-wrapper {
  position: relative;
  display: inline-block;
  padding-top: 0.2em;
  padding-right: 0.05em;
  padding-bottom: 0.1em;
  overflow: hidden;
}

.letter {
  display: inline-block;
  line-height: 1em;
}

@media (max-width: 959px) {

  .tesC {
    margin: auto;
    width: 96vmin;
    height: 54vmin;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  .youtube {
    width: 98%;
    height: 98%;
  }

  #dayWrapper {
    top: auto;
    left: auto;
    bottom: 5px;
    width: 98vw;
  }

  .capbase {
    font-size: calc(100vmin / 15);
    padding-right: 5px;
  }

  .agobase {
    font-size: calc(100vmin / 25);
    padding-right: calc(5px + 0.2em);
  }

  .cap {
    top: 80vh;
    left: 0;
    width: 100vw;
    font-size: 8vw;
  }
}
</style>