import { createApp } from 'vue'
import App from './App.vue'
import jQuery from 'jquery'
global.jquery = jQuery
global.$ = jQuery
window.$ = window.jQuery = require('jquery')

import { library } from '@fortawesome/fontawesome-svg-core'
import { faSync, faInfo, faUndo, faCog, faHashtag, faAsterisk } from '@fortawesome/free-solid-svg-icons'
import { faYoutube, faTwitter } from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

library.add(faYoutube, faTwitter, faSync, faInfo, faUndo, faCog, faHashtag, faAsterisk);

var app = createApp(App)
app.component('fa', FontAwesomeIcon)
app.mount('#app')